import React, { Component } from 'react';   
//import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import Modal from 'react-awesome-modal';
import ReactPlayer from 'react-player';
import * as config from './../../config';

class NotificationComponent extends Component{
    constructor(props) {
        super(props);
        this.state = {
           visible : false
        };

        this.notificationInfo = this.props.notificationInfo;
    }

    openModal() {
        this.setState({
            visible : true
        });
    }

    closeModal() {
        this.props.modalIsClose();
    }

    render() { 
        let url="";
        if(this.notificationInfo.file_type!==''){
            let path = config.mediaPath[this.notificationInfo.file_type]
            url = path.filePath + this.notificationInfo.file_name;
        }
        
        return (
          <div>
            <Modal 
                visible={this.props.modalIsOpen} 
                width="500" 
                effect="fadeInDown"
            >
                <div style={{ padding:'15px'}}>
                    <div className="notification-header">
                        <div style={{ float :'left' }}>
                            <p>{this.notificationInfo.title}</p>
                        </div>
                        <div style={{ float :'right' }}>
                            <button type="button" className="close" aria-label="Close" onClick={ event => this.closeModal()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div style={{ clear :'both' }}></div>
                    </div>
                    <div className="notification-content">
                        {this.notificationInfo.file_type ==='video' ? <ReactPlayer url={url}  className='react-player' playing  controls width='100%' height='100%'/>
                        :this.notificationInfo.file_type ==='image'? <img src={url}  alt="Notification" width='100%' height='100%'/>:''}
                    </div>
                    <div style={{ marginTop:'10px'}}>
                        <p>{this.notificationInfo.description}</p>
                    </div>
                </div>

            </Modal>
          </div>
        );
      }
}

// function mapDispatchToProps(dispatch){
//     return bindActionCreators({
       
//     }, dispatch)
// }

function mapStateToProps({login}) { 
    return {
        
    }
}

export default withRouter(connect(mapStateToProps, null)(NotificationComponent));

