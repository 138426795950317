import React, { Component } from 'react';   
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import * as Actions from './../../store/actions';
import logo from './../../images/logo.svg';
import logonew from './../../images/logonewlogin.PNG';
import SimpleBar from 'simplebar-react';
import '../../../node_modules/simplebar/dist/simplebar.css';
import '../../../node_modules/toasted-notes/src/styles.css'; 
import history from './../../history';
import NotificationPage from './NotificationPage';
import ChangePassword from './../auth/Changepassword';
import Help from './Help';
import * as config from './../../config'; 

class HeaderComponent extends Component{
    constructor(props) {
        super(props);
        this.state = {
           currpassword:'',
           newpassword:'',
           confpassword:'',
           currpasswordError:'',
           newpasswordError:'',
           confpasswordError:'',
           changepasserror:'',
           changepasssuccess:'',
           notificationData:[],
           modalIsOpen:false,
           notification:'',
           notificationInfo:'',
           notificationCount:'',
           changepassModalIsOpen:false,
           showHelp:false,
           showProgressbar:true
        }
        this.access_token = localStorage.getItem('access_token');
        this.user = JSON.parse(localStorage.getItem('userdata'));
        this.usertype = localStorage.getItem("usertype");
    }

    componentDidMount() 
    { 
        if(this.access_token && this.user){
            this.props.getNotificationCount({'user_id':this.user.id})
        }
        else{
            if(this.usertype){
                const path  = config.userTypeRoutes[this.usertype]
                if (path) {
                    history.push(path.loginPath)
                }
            }
            else{
                const path  = config.userTypeRoutes['youth']
                if (path) {
                    history.push(path.loginPath)
                }
            }
        }
    }

    onPropertyChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value});
    }

    onClickChapass = () =>{
        this.setState({changepassModalIsOpen: true,});
    }

    componentWillReceiveProps = (nextProps) => { 
        const notificationCount =  nextProps.headerdata.notificationcount;
        const notifications = nextProps.headerdata.notificationdata;
       
        if(notificationCount){ 
            this.setState({notificationCount:notificationCount.count})
        }

        if(notifications){
            if(notifications.status_code===200){ 
                this.setState({notificationData:notifications.data, showProgressbar:false})
            }
            if(notifications.status_code===201){
                this.setState({showProgressbar:false})
            }
        }
        
    }

    onClickProfile = () =>{
        history.push('/myprofile')
    }

    onClickSignout = () =>{
        localStorage.removeItem("access_token");
        localStorage.removeItem("userdata");
        localStorage.removeItem("usertype");
        const path  = config.userTypeRoutes[this.usertype]
        if (path) {
            history.push(path.loginPath)
        }
    }

    onClickLogo = () =>{ 
       const path  = config.userTypeRoutes[this.usertype]
        if (path) {
            history.push(path.basePath)
        }
    }

    onClickNotification = () => { 
        this.props.getNotifications({'user_id':this.user.id});
    }

     openNotificatioModal = async (data) =>{
        this.setState({modalIsOpen: true, notificationInfo:data});
        await this.props.changeNotificationStatus({
                                user_id:this.user.id,
                                notification_id:data.id
                            });
        this.props.getNotificationCount({'user_id':this.user.id});
        this.props.getNotifications({'user_id':this.user.id});
    }

    closeNotificatioModal = () =>{
        this.setState({modalIsOpen: false})
    }

    closeChangepasswordModal = () =>{
        this.setState({changepassModalIsOpen: false})
    }

    onClickHelp = () =>{ 
        this.setState({showHelp: true})
    }

    closeShowHelp = () =>{
        this.setState({showHelp: false})
    }

    render(){
        return(
            <div className="container-hold">        
                <nav className={localStorage.getItem("usertype") === "youth" ? "navbar navbar-expand navbar-gradient": "navbar navbar-expand navbar-gradientnew"}>
                    <div className="container" style={{zIndex :'800'}}>
                        <div className="navbar-brand" > 
                            <div className="logoaction" onClick={this.onClickLogo} ></div>
                            <img src={localStorage.getItem("usertype") === "youth" ? logo : logonew}  className="logo" alt="logo"/>
                        </div>
                       
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample02" aria-controls="navbarsExample02" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span> 
                        </button> 

                        <div className="collapse navbar-collapse" id="navbarsExample02">
                            <ul className="navbar-nav mr-auto">
                            
                            </ul>
                            <div className="form-inline my-2 my-md-0">

                                <div className="btn-group action-group">
                                   {/* {this.usertype==='youth' ? 
                                        <div className="notification-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                            {this.state.notificationCount >0 ? <div className="notification-red-dot"></div> :''}  */}
                                            <div className="notification-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                            <i className="la la-bell-o" onClick={this.onClickNotification}></i>
                                        </div>
                                    :''}
                                    <div className="dropdown-menu dropdown-menu-right notification-hold">
                                        <SimpleBar style={{ maxHeight: '340px' }}>
                                            {this.state.showProgressbar ? <div className="no-notifications-loader"><div className="loader">Loading...</div></div> :
                                                this.state.notificationData.length>0 ?
                                                this.state.notificationData.map(notification =>{
                                                    return <div key={notification.id}>
                                                        <div className={notification.view_status===1 ? 'dropdown-item' : 'dropdown-item not-read-notification'}>
                                                            <div className="row" onClick={()=>{this.openNotificatioModal(notification)}}>
                                                                <div className="col-xl-3 col-3 play-circle">
                                                                    <i className={notification.file_type==='video'? 'la la-play-circle notefication-ico':notification.file_type==='image'?'la la-file-image-o notefication-ico':notification.file_type===''?'la la-file-text-o notefication-ico':''}></i>
                                                                </div>
                                                                <div className="col-xl-9 col-9"> 
                                                                    <span className="notification-title">{notification.title}</span>
                                                                    <span className="notification-dec"> {notification.description}</span>
                                                                    <span className="date">{notification.send_notification_date} </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="dropdown-divider"></div>
                                                </div>
                                                })  :<div className="no-notifications">Notifications not available</div> 
                                            }
                                        </SimpleBar>
                    
                            </div>
                        </div>
                        <div className="btn-group action-group">
                            <div className="userimg-hold" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <div className="userimg">
                                        <i className="la la-user" ></i>
                                    </div>
                                    <i className="la la-ellipsis-v"></i>
                            </div>

                            <div className="dropdown-menu dropdown-menu-right">
                                <div className="dropdown-item" onClick={this.onClickProfile}><i className="la la-user"></i>Profile</div>
                                <div className="dropdown-item" onClick={this.onClickChapass} ><i className="la la-lock" ></i>Change Password</div>
                                <div className="dropdown-item" onClick={this.onClickHelp}><i className="la la-question-circle"></i>Help</div>
                                <div className="dropdown-divider"></div>
                                <div className="dropdown-item" href="" onClick={this.onClickSignout}><i className="la la-sign-out" ></i>Sign Out</div>
                            </div>
                        </div>

                        </div>
                    </div>
                        {/* Open changepassword modal */}
                        {
                            this.state.changepassModalIsOpen ? <ChangePassword
                                modalIsOpen = {this.state.changepassModalIsOpen}
                                modalIsClose = {this.closeChangepasswordModal}
                            ></ChangePassword> :''
                        }   
                        
                    </div>
                </nav>

                        {/* Open notification details modal */}
                        {
                            this.state.modalIsOpen ? <NotificationPage
                                modalIsOpen = {this.state.modalIsOpen}
                                modalIsClose = {this.closeNotificatioModal}
                                notificationInfo = {this.state.notificationInfo}
                            ></NotificationPage> :''
                        } 

                        {/*open help modal */} 
                        {
                            this.state.showHelp ? <Help
                                helpIsOpen = {this.state.showHelp}
                                helpIsClose = {this.closeShowHelp}
                            ></Help> :''
                        }
        </div>
        )
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
       getNotifications:Actions.getNotifications,
       getNotificationCount:Actions.getNotificationCount,
       changeNotificationStatus:Actions.changeNotificationStatus
    }, dispatch)
}

function mapStateToProps({changePassword}) { 
    return {
       headerdata:changePassword
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderComponent));
