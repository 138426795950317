import * as config from './../config';
import history from './../history';
import axios from 'axios';

const API_URL = config.API_URL;

export const country = () => {
    return new Promise((resolve, reject) => { 
        axios({
            method: 'get',
            url : API_URL+'/api/auth/get_country'
        }).then(response => { 
            if(response){
                resolve(response);
            } 
        }).catch(error => {
            return error;
        });
    });
}

export const city = () => {
    return new Promise((resolve, reject) => { 
        axios({
            method: 'get',
            url: API_URL+"/api/auth/get_cities/US"
        }).then(response => { 
            if(response){
                resolve(response);
            } 
        }).catch(error => {
            return error;
        });
    });
}

export const state = () => {
    return new Promise((resolve, reject) => { 
        axios({
            method: 'get',
            url: API_URL+"/api/auth/get_states"
        }).then(response => { 
            if(response){
                resolve(response);
            } 
        }).catch(error => {
            return error;
        });
    });
}


export const userRegister = (user) =>{ 
    return new Promise((resolve, reject) => { 
        axios({
            method: 'post',
            url: API_URL+"/api/auth/signup",
            config: {
                headers: {
                    'content-type': 'application/json',
                }
            },
            data:user
        }).then(response => { 
            if(response){
                resolve(response);
            } 
        }).catch(error => {
            return error;
        });
    });
}

export const forgotpass = (userdata) =>{
    return new Promise((resolve, reject) => { 
        axios({
            method: 'post',
            url: API_URL+"/api/auth/newpassword",
            config: {
                headers: {
                    'content-type': 'application/json',
                }
            },
            data:userdata
        }).then(response => { 
            if(response){
                resolve(response);
            } 
        }).catch(error => {
            return error;
        });
    });
}

export const userLogin = (user) =>{
        var headers= {
            'Content-Type': 'application/json',
        }

        let data = {
            method: "POST",
            body: JSON.stringify(user),
            headers: headers,
        }
        return  fetch(API_URL+"/api/auth/login", data)
            .then((response) => response.json())
            .then((responseJson) => {
                return responseJson;
        })
        .catch((error) => {
            return error;
        });
}

export const changepass = (passdata, access_token) =>{ 
    var headers= {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access_token
    }

    let data = {
        method: "POST",
        body: JSON.stringify(passdata),
        headers: headers,
    }
    return  fetch(API_URL+"/api/auth/reset", data)
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
    })
    .catch((error) => {
        return error;
    });
}

export const editUser = (user,access_token) =>{ 
    var headers= {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access_token
    }

    let data = {
        method: "PUT",
        body: JSON.stringify(user),
        headers: headers,
    }
    return  fetch(API_URL+"/api/auth/profile/"+user.id, data)
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
    })
    .catch((error) => {
        return error;
    });
}


export const notificationCount = (userid) =>{ 
    var headers= {
        'Content-Type': 'application/json',
    }

    let data = {
        method: "POST",
        body: JSON.stringify(userid),
        headers: headers,
    }
    return  fetch(API_URL+"/api/notification/count", data)
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
    })
    .catch((error) => {
        return error;
    });
}

export const notifications = (user) =>{ 
    var headers= {
        'Content-Type': 'application/json',
    }
    let data = {
        method: "POST",
        body: JSON.stringify(user),
        headers: headers,
    }
    return  fetch(API_URL+"/api/notification/youth", data)
        .then((response) => response.json())
        .then((responseJson) => { 
            return responseJson;
    })
    .catch((error) => {
        return error;
    });
}

export const notificationStatus = (notification) =>{ 
    var headers= {
        'Content-Type': 'application/json',
    }
    
    let data = {
        method: "POST",
        body: JSON.stringify(notification),
        headers: headers,
    }
    return  fetch(API_URL+"/api/notification/notify_status", data)
        .then((response) => response.json())
        .then((responseJson) => { 
            return responseJson;
        })
        .catch((error) => {
            return error;
    });
}

export const refreshToken = () =>{
    var headers= {
        'Content-Type': 'application/json',
    }

    const token = localStorage.getItem('access_token');
    const userdata = JSON.parse(localStorage.getItem('userdata'));

    if(userdata&&token){
        let user = {
            'email' : userdata.email,
            'token' : token
        }
        
        let data = {
            method: "POST",
            body: JSON.stringify(user),
            headers: headers,
        }
        return  fetch(API_URL+"/api/auth/refresh", data)
            .then((response) => response.json())
            .then((responseJson) => { 
                localStorage.setItem('access_token', responseJson.token);
                return responseJson;
            })
            .catch((error) => {
            return error;
        });
    }else{
        const path  = config.userTypeRoutes['youth']
        if (path) {
            history.push(path.loginPath)
        }
        return false;
    }
}

export const courses =  (access_token, user_type) => {
    var headers= {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access_token
    }
    
    let data = {
        method: "POST",
        body:JSON.stringify(user_type),
        headers: headers,
    }
    return  fetch(API_URL+"/api/auth/courses_dashboard", data)
        .then((response) => response.json())
        .then((responseJson) =>  { 
            return responseJson;
            
        })
        .catch((error) => {
            return error;
    });
}