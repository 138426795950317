import React, { Component } from 'react';   
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import Modal from 'react-awesome-modal';
import * as Actions from './../../store/actions';
import Message from './../common/Messages';
import {emailValidation} from './../validations/validations';

class ForgotpasswordComponent extends Component{
    constructor(props) {
        super(props);
        this.state = {
            email:'',
            visible : false,
            emailError: '',
            forgotpasserror: '',
            forgotpasssuccess: '',
            showMessage:false,
            messageData:''
        };
    }

    closeModal() {
        this.props.modalIsClose();
        this.setState({
            email:'',
            emailError: '',
            forgotpasserror: '',
            forgotpasssuccess: '',
          });
    }

    onPropertyChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    ForgotPassValidate = () => {
       let emailError = emailValidation(this.state.email);
       if(emailError){
           this.setState({forgotpasssuccess: '', forgotpasserror:'', emailError});
           return false;
       }
       return true;
    }

    onClickForgotPass = () => {
        const isValid = this.ForgotPassValidate();

        if (isValid) {
            var userdata = {
                email: this.state.email
            };

            this.props.submitForgotpass(userdata)
        }
    }

    componentWillReceiveProps(nextProps) {
        const forgotpassinfo = nextProps.login.forgotpassdata.data;
        if (forgotpassinfo) {
            if (forgotpassinfo.status_code === 404) {
                this.setState({
                    forgotpasserror: "Email address doesn't exists",
                    emailError: ''
                });
            }
            if (forgotpassinfo.status_code === 200) {
                this.setState({
                    forgotpasserror: '',
                    emailError: '',
                    email: '',
                    showMessage:true,
                    messageData:{type:'success',message:"Email sent successfully"}
                });
            }

        }
    }

    closeShowMessage = () =>{
        this.props.modalIsClose();
        this.setState({showMessage: false})
    }


    render() {
        const { email } = this.state; 
        return ( 
          <div>
            <Modal 
                visible={this.props.forgotpassModalIsOpen} 
                width="500" 
                effect="fadeInDown"
            >
                 <div>
                 <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Forgot Password</h4>
                                <button type="button" className="close" onClick={ event => this.closeModal()}>&times;</button>
                            </div>

                            <div className="modal-body">
                                <div className="col-xl-12">
                                    <div className="alert alert-info">
                                        Enter the email and click on submit to recover password
                                    </div>
                                    {!!this.state.forgotpasssuccess && <div style={{ color: 'green' }} className="reg-error" >{this.state.forgotpasssuccess}</div>}
                                </div>

                                <div className="col-xl-12">
                                    <div className="form-group">
                                        <label htmlFor="pass">Email Address</label>
                                        <input type="text" className={this.state.emailError ? 'form-control errorborder' : 'form-control'} value={email}
                                            name="email" onChange={this.onPropertyChange} tabIndex="1" />
                                        {(this.state.emailError ? <div style={{ color: 'red', marginTop: '5px' }} className="reg-error" >{this.state.emailError}</div> : '')}
                                        {(this.state.forgotpasserror ? <div style={{ color: 'red', marginTop: '5px' }} className="reg-error" >{this.state.forgotpasserror}</div> : '')}
                                    </div>
                                </div>
                            </div>


                            <div className="modal-footer">
                                <input type="button" className="btn btn-outline-secondary margin-btn-left" value="CANCEL" onClick={ event => this.closeModal()} tabIndex="2"/>
                                <input type="button" className="btn btn-primary " value="SUBMIT" onClick={this.onClickForgotPass} tabIndex="3"/>
                            </div>

                        </div>
                 </div>
            </Modal>

            {/*open message modal */} 
            {
                this.state.showMessage ? <Message
                    msgIsOpen = {this.state.showMessage}
                    msgIsClose = {this.closeShowMessage}
                    messageData = {this.state.messageData}
                ></Message> :''
            }
          </div>
        );
      }
}

function mapDispatchToProps(dispatch)  {
    return bindActionCreators({
        submitForgotpass: Actions.forgotpassword
    }, dispatch)
}

function mapStateToProps ({ loginUser }) {
    return {
        login: loginUser
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotpasswordComponent));

