import React, { Component } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import history from './history'

import getStore from './store';

import loginPage from './app/auth/LoginPage';
import registerPage from './app/auth/RegisterPage';
import mycoursePage from './app/course/youth/MycoursePage';
import profilePage from './app/auth/ProfilePage';
import showCoursePage from './app/course/youth/ShowcoursePage';
import parentsPage from './app/course/parents/ParentsPage';
import professionalPage from './app/course/parents/ProfessionalsPage';

require('dotenv').config();

class App extends Component {
  render() {
    const store = getStore();
    return (
      <div>
        <Provider store={store}>
            <Router history={history}>
                <div>
                    <Switch>
                        {/* Login url for youth, parents, professionals user. 
                        if change this url also change in LoginPage.js to split & get usertype */}
                        <Route path="/" component={loginPage} exact/>
                        <Route path="/login/youth" component={loginPage}/>
                        <Route path="/login/parents" component={loginPage}/>
                        <Route path="/login/professionals" component={loginPage}/>
                        {/* Register url for youth, parents, professionals user. 
                        if change this url also change in RegisterPage.js to split & get usertype */}
                        <Route path="/register/youth" component={registerPage}/>
                        <Route path="/register/parents" component={registerPage}/>
                        <Route path="/register/professionals" component={registerPage}/>
                        <Route path="/myprofile" component={profilePage}/>
                        <Route path="/youth" component={mycoursePage} />
                        <Route path="/showcourse/:id" component={showCoursePage}/>
                        <Route path="/parents" component={parentsPage}/>
                        <Route path="/professionals" component={professionalPage}/>
                    </Switch>
                </div>
            </Router>
        </Provider>
      </div>
    );
  }
}

export default App;