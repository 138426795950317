import React, { Component } from 'react';   
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import * as config from './../../../config';
import {scoid, scormDataCount, initializeElem, initializeCache} from './../../../services/scormServices';
import IframeComm from "react-iframe-comm";

const sco_object = {};
const url = config.mediaPath['scormindex'];

const attributes = {
    src: url.filePath+"index.html",
    width: "100%",
    height: window.innerHeight,
    frameBorder: 0, 
};

class ShowcourseComponent extends Component{
    constructor(props) {
        super(props);
        this.state = {
            course:'',
            scoObject:''
        }
    }


   async componentDidMount()
    { 
        document.body.classList.toggle('scormbody', true)
        this.access_token = localStorage.getItem('access_token');
        const userdata = localStorage.getItem('userdata');
        const user = JSON.parse(userdata);
        const scormpath = config.mediaPath['scormpath'];
        
        var data ={
            course_id:this.props.Course.id
        }
        let sco_id = await scoid(data, this.access_token);

        //console.log("Course ;", this.props.Course)
        sco_object['sco_item_base']= scormpath.filePath;
        if(this.props.Course.course_url){
              sco_object['sco_item_url']=scormpath.filePath+this.props.Course.base_folder+"/"+this.props.Course.course_url;
        }
        else{
            alert("Something went wrong! we are not able to find scorm content");
            return false;
        }
        sco_object['sco_callback_url']='http://34.203.217.49:8080/api/auth/update_scormdata';
        sco_object['sco_id']=sco_id;
        sco_object['accessToken']=this.access_token;
        sco_object['courseId']=this.props.Course.id;

        let count = await scormDataCount(this.props.Course.id, this.access_token)
        //console.log("count : ", count)
        if(count===''){
            //console.log("no count")
            this.initializeElement('cmi.core.score._children','cmi.core.student_id,cmi.core.student_name,cmi.core.lesson_location,cmi.core.credit,cmi.core.lesson_status,cmi.core.entry, cmi.core.score.raw,  cmi.core.score.max,cmi.core.score.min,cmi.core.total_time,cmi.core.exit,cmi.core.session_time, cmi.suspend_data, cmi.launch_data');
            this.initializeElement('masteryscore','');
            this.initializeElement('student.course.sco.id',sco_id);
            this.initializeElement('cmi._version','3.4');
            this.initializeElement('session._session_id','1');
            this.initializeElement('session._IsFinished','false');
            this.initializeElement('cmi.core.student_id',user.id);
            this.initializeElement('cmi.core.student_name',user.nickname);
            this.initializeElement('cmi.core.lesson_location','');
            this.initializeElement('cmi.core.credit','credit');
            this.initializeElement('cmi.core.lesson_status','incompleted');
            this.initializeElement('cmi.core.entry','');
            this.initializeElement('cmi.core.score.max','');
            this.initializeElement('cmi.core.score.min','');
            this.initializeElement('cmi.core.total_time','0000:00:00.00');
            this.initializeElement('cmi.core.exit','');
            this.initializeElement('cmi.core.session_time','');
            this.initializeElement('cmi.core.score.raw','');
            this.initializeElement('cmi.suspend_data','');
            this.initializeElement('cmi.launch_data','');
            this.initializeCach()
        }
        else{
           this.initializeCach()
        }
    }

    async initializeCach(){
        let elementData = await initializeCache(this.props.Course.id, this.access_token)
        const sco_obj = {};
        elementData.forEach(element => { 
            sco_obj[element.scorm_var]=element.scorm_value;
        });

        sco_object['sco_obj']=sco_obj;
        this.setState({scoObject: sco_object})
    }

    initializeElement(variable,variablevalue){
        initializeElem(this.props.Course.id,variable,variablevalue,this.access_token)
    }

    componentWillUnmount() {
        document.body.classList.remove('scormbody')
    }

    render(){
        return(
            <div>
                <div className="course-container"> 
                    {this.state.scoObject ?
                        <IframeComm
                            attributes={attributes}
                            postMessageData={this.state.scoObject}
                        />
                    :''}
                </div>
            </div>

        )
    }
    }

function mapDispatchToProps(dispatch){
    return bindActionCreators({
    }, dispatch)
}

function mapStateToProps({mycourse}) { 
    return {
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShowcourseComponent));
