import {changepass, notificationCount, notifications, notificationStatus, refreshToken} from './../../services/services'

export const CHANGEPASS_SUCCESS = 'CHANGEPASS_SUCCESS';
export const CHANGEPASS_ERROR = 'CHANGEPASS_ERROR';
export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS';
export const NOTIFICATIONCOUNT_SUCCESS = 'NOTIFICATIONCOUNT_SUCCESS';
export const NOTIFICATIONSTATUS_SUCCESS = 'NOTIFICATIONSTATUS_SUCCESS';

export function changepassword(passdata, access_token){ 
    return (dispatch) =>
        changepass(passdata, access_token).then((response)=>{
            if(response.error){ 
                if(response.error.status_code===401){  //call refreshToken when token experied for get refresh token and continue with changepass
                    refreshToken().then((res)=>{
                        return changepass(passdata,res.token)
                    }).then((resp) => {    
                        if(resp.data.status_code===200){  
                            return dispatch({
                                type   : CHANGEPASS_SUCCESS,
                                payload: { message: resp.data.message },
                            });
                        }
                    })
                }
                else{ 
                    return dispatch({
                        type   : CHANGEPASS_ERROR,
                        payload: { message: response.error.message },
                    });
                }
            }
            else{ 
                if(response.data.status_code===200){  
                        return dispatch({
                            type   : CHANGEPASS_SUCCESS,
                            payload: { message: response.data.message },
                        });
                    }
            }
        })
}

export function getNotifications(user){ 
    return (dispatch) =>
        notifications(user).then((response)=>{ 
            if(response.data){ 
                    return dispatch({
                        type   : NOTIFICATION_SUCCESS,
                        payload: response,
                    });
            }
        })
}

export function getNotificationCount(userid){ 
    return (dispatch) =>
        notificationCount(userid).then((response)=>{ 
            if(response.data){
                if(response.data.status_code===200){  
                        return dispatch({
                            type   : NOTIFICATIONCOUNT_SUCCESS,
                            payload: response.data,
                        });
                    }
            }
        })
}

export function changeNotificationStatus(notification){ 
    return (dispatch) =>
        notificationStatus(notification).then((response)=>{ 
            if(response.data){ 
                if(response.data.status_code===200){  
                        return dispatch({
                            type   : NOTIFICATIONSTATUS_SUCCESS,
                            payload: response.data,
                        });
                    }
            }
        })
}

