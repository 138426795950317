import thunk from "redux-thunk";
import { applyMiddleware, createStore } from "redux";
//import { createLogger } from "redux-logger";
import rootReducer from "./store/reducers";

const store = initialState => {
   // const logger = createLogger();
    const store = createStore(rootReducer, initialState, applyMiddleware(thunk));
    return store;
};

export default store;