import * as Actions from '../actions';

const initialState = {
    status:'',
    message:'',
    registerdata:'',
    countrydata:'',
    citydata:'',
    editdata:'',
    statedata:''
};

const registerUser = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.REGISTER_SUCCESS:
        {
            return {
               ...state,
               status: true,
               registerdata  : action.payload
            };
        }
        case Actions.REGISTER_ERROR:
        {
            return {
                ...state,
                status: false,
                registerdata  : action.payload
            };
        }
        case Actions.GETCOUNTRY_SUCCESS:
        {
            return {
                ...state,
                countrydata: action.payload
            };
        }
        case Actions.GETCITY_SUCCESS:
        {
            return {
                ...state,
                citydata: action.payload
            };
        }
        case Actions.EDIT_SUCCESS:
        {
            return {
                ...state,
                editdata: action.payload
            };
        }
        case Actions.GETSTATE_SUCCESS:
        {
            return {
                ...state,
                statedata: action.payload
            };
        }
        default:
        {
            return state
        }
    }
};

export default registerUser;