import React, { Component } from 'react';  
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import Modal from 'react-awesome-modal';

class MessagesComponent extends Component{
    constructor(props) {
        super(props);
        this.state = {
            visible : false,
        };
        this.messageData = this.props.messageData
    }

    componentWillUnmount() {
        this.props.msgIsClose();
    }

    closeModal = () => {
        this.props.msgIsClose();
    }
   

    render() {
        
        return ( 
          <div >
            <Modal 
                visible={this.props.msgIsOpen} 
                width="400" 
                effect="fadeInDown"
            >
                <div>
                    <div className={this.messageData.type==='success' ? "modal-content alertmessage success-alert " : 
                                    this.messageData.type==='error' ? "modal-content alertmessage error-alert ":
                                    this.messageData.type==='info' ? "modal-content alertmessage info-alert " :''}>
                        <div className="modal-header">
                        </div>
                        <div className="modal-body">
                            {this.messageData.message}
                        </div>
                        
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary onlyokbutton"  onClick={ event => this.closeModal()}>OK</button>
                        </div>
                    </div>
                </div>
            </Modal>
          </div>
        );
      }
}


function mapStateToProps ({message}) {
    return {
        
    }
}

export default withRouter(connect(mapStateToProps, null)(MessagesComponent));

